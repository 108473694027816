import Engine from "../../core/Engine/Engine";
import Entity from "../entity/entity";
import Player from "../player/player";
import Projectile from "../projectile/projectile";

class Monster extends Entity {
  speed: number;
  direction: number;
  private moveInterval: number = 2000;
  private timeSinceLastMove: number = 0;
  private attackRange: number = 125;
  private targetX: number;
  private targetY: number;

  // Projectile speed
  private projectileSpeed: number = Math.floor(Math.random() * 100) + 100; // Adjust as needed
  private projectileCooldown: number = 1000;
  private timeSinceLastShot: number = 0; // Time since last projectile fired
  public engine: Engine;

  constructor(
    x: number,
    y: number,
    width: number,
    height: number,
    color: string,
    speed: number,
    engine: Engine
  ) {
    super(x, y, width, height, color, 2);
    this.speed = speed;
    this.direction = Math.random() * 2 * Math.PI; // Initial random direction
    this.targetX = x;
    this.targetY = y;
    this.engine = engine;
  }

  update(gameTick: number, canvas: HTMLCanvasElement, player: Player): void {
    this.timeSinceLastMove += 1;
    this.timeSinceLastShot += 1;

    const dx = player.x + player.width / 2 - (this.x + this.width / 2); // Calculate from centers
    const dy = player.y + player.height / 2 - (this.y + this.height / 2);
    const distanceToPlayer = Math.sqrt(dx * dx + dy * dy);

    // Update target position and direction if needed
    this.updateTargetAndDirection(distanceToPlayer, player);

    // Calculate movement based on target
    const moveVector = this.calculateMoveVector(distanceToPlayer);

    // Apply movement
    this.x += moveVector.x * gameTick;
    this.y += moveVector.y * gameTick;

    // Shoot projectile if within attack range and cooldown is ready
    if (
      distanceToPlayer <= this.attackRange &&
      this.timeSinceLastShot >= this.projectileCooldown / 16
    ) {
      this.shootProjectile(player);
      this.timeSinceLastShot = 0; // Reset cooldown
    }
  }

  public updateTargetAndDirection(distanceToPlayer: number, player: Entity) {
    // Update target position only when out of follow range
    if (distanceToPlayer > this.attackRange) {
      // Recalculate target position towards the player
      const angleToPlayer = Math.atan2(player.y - this.y, player.x - this.x);
      this.targetX =
        player.x +
        player.width / 2 +
        Math.cos(angleToPlayer) * this.attackRange;
      this.targetY =
        player.y +
        player.height / 2 +
        Math.sin(angleToPlayer) * this.attackRange;

      if (this.timeSinceLastMove >= this.moveInterval / 16) {
        this.timeSinceLastMove = 0;
        // Update direction based on angle to player
        this.direction = angleToPlayer;
      }
    }
  }

  private calculateMoveVector(distanceToPlayer: number) {
    const targetDistance = Math.sqrt(
      (this.targetX - this.x) * (this.targetX - this.x) +
        (this.targetY - this.y) * (this.targetY - this.y)
    );

    if (targetDistance > this.speed && distanceToPlayer > this.attackRange) {
      // Move towards target if far enough
      return {
        x: ((this.targetX - this.x) / targetDistance) * this.speed,
        y: ((this.targetY - this.y) / targetDistance) * this.speed,
      };
    } else {
      // Controlled jitter within attack range
      const jitterAmount = this.speed * 0.5;
      return {
        x: (Math.random() - 0.5) * jitterAmount,
        y: (Math.random() - 0.5) * jitterAmount,
      };
    }
  }

  private shootProjectile(player: Entity) {
    const dx = player.x + player.width / 2 - (this.x + this.width / 2);
    const dy = player.y + player.height / 2 - (this.y + this.height / 2);
    const angle = Math.atan2(dy, dx);

    this.engine.addEntity(
      new Projectile(
        this.x + this.width / 2,
        this.y + this.height / 2,
        Math.cos(angle),
        Math.sin(angle),
        this.projectileSpeed
      )
    );
  }

  shouldComponentUpdate(nextProps: Monster) {
    const threshold = 0.1; // Adjust this threshold as needed
    return (
      Math.abs(nextProps.x - this.x) > threshold ||
      Math.abs(nextProps.y - this.y) > threshold
    );
  }

  public getMoveInterval(): number {
    return this.moveInterval;
  }
  public getTimeSinceLastMove(): number {
    return this.timeSinceLastMove;
  }
  public getAttackRange(): number {
    return this.attackRange;
  }
  public getTimeSinceLastShot(): number {
    return this.timeSinceLastShot;
  }
  public getTargetX(): number {
    return this.targetX;
  }
  public getTargetY(): number {
    return this.targetY;
  }
  public setTimeSinceLastMove(value: number): void {
    this.timeSinceLastMove = value;
  }
  public setAttackRange(value: number): void {
    this.attackRange = value;
  }
  public setTimeSinceLastShot(value: number): void {
    this.timeSinceLastShot = value;
  }
}

export default Monster;
