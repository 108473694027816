import { useNavigate } from "react-router-dom";
import Button from "../../components/button/Button";
import Game from "../../../game/components/Game/Game";

const GamePage: React.FC = () => {
  const navigate = useNavigate();
  const handleNavigation = (urlPath: string) => {
    navigate(urlPath);
  };

  return (
    <div className="game-page">
      <Button text="Home" onClick={() => handleNavigation("/")} />
      <div>W, A, S, D to move </div>
      <Game />
    </div>
  );
};

export default GamePage;
