import { useEffect, useRef, useState } from "react";
import { useGameEngine } from "../../hooks/useGameEngine/useGameEngine";

interface GameProps {
  width?: number;
  height?: number;
}

const MIN_WIDTH = 400;

function Game({ width = 800, height = 450 }: GameProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [canvasDimensions, setCanvasDimensions] = useState({ width, height });

  useEffect(() => {
    const handleResize = () => {
      const uiWidth = window.innerWidth * 0.25;
      let gameWidth = Math.max(window.innerWidth - uiWidth, MIN_WIDTH);
      let gameHeight = Math.max(gameWidth / (16 / 9));

      if (gameHeight > window.innerHeight) {
        gameHeight = window.innerHeight;
        gameWidth = gameHeight * (16 / 9);
      }

      setCanvasDimensions({ width: gameWidth, height: gameHeight });
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <GameContent
      canvasRef={canvasRef}
      width={canvasDimensions.width}
      height={canvasDimensions.height}
    />
  );
}

function GameContent({
  canvasRef,
  width,
  height,
}: {
  canvasRef: React.RefObject<HTMLCanvasElement>;
  width: number;
  height: number;
}) {
  useGameEngine(canvasRef);

  return (
    <div>
      <canvas
        ref={canvasRef}
        width={width}
        height={height}
        data-testid="game-canvas"
      />
    </div>
  );
}

export default Game;
