import Player from "../../entities/player/player";

export class InputHandler {
  keys: { [key: string]: boolean } = {};
  private lastKeyPressed: string | null = null;
  private player: Player | null = null;

  constructor(player: Player | null) {
    this.player = player;
  }

  handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === this.lastKeyPressed || !this.player) return;
    this.lastKeyPressed = event.key;
    this.keys[event.key] = true;
  };

  handleKeyUp = (event: KeyboardEvent) => {
    this.keys[event.key] = false;
    this.lastKeyPressed = null;
  };
}
