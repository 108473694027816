import Player from "../player/player";
import Monster from "../monster/monster";
import Entity from "../entity/entity";
import Engine from "../../core/Engine/Engine";

export interface EntityCreationOptions {
  x: number;
  y: number;
  width: number;
  height: number;
  color: string;
  speed: number;
}

function createEntity(
  entityType: string,
  options: EntityCreationOptions,
  engine: Engine
): Entity {
  switch (entityType) {
    case "player":
      return new Player(
        options.x,
        options.y,
        options.width,
        options.height,
        options.color,
        options.speed,
        100
      );
    case "monster":
      return new Monster(
        options.x,
        options.y,
        options.width,
        options.height,
        options.color,
        options.speed,
        engine
      );
    default:
      throw new Error(`Unsupported entity type: ${entityType}`);
  }
}

// Convenience functions for specific entity types
function createPlayer(
  gameFieldWidth: number,
  gameFieldHeight: number,
  engine: Engine
): Player {
  return createEntity(
    "player",
    {
      x: gameFieldWidth / 2,
      y: gameFieldHeight / 2,
      width: 30,
      height: 30,
      color: "green",
      // Player speed
      speed: 100,
    },
    engine
  ) as Player;
}

function createMonsters(
  gameFieldWidth: number,
  gameFieldHeight: number,
  numMonsters: number,
  engine: Engine
): Monster[] {
  const monsters: Monster[] = [];

  for (let i = 0; i < numMonsters; i++) {
    const x = Math.floor(Math.random() * gameFieldWidth - 20);
    const y = Math.floor(Math.random() * gameFieldHeight - 20);
    const color = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    const width = Math.floor(Math.random() * 20) + 10;
    const height = Math.floor(Math.random() * 20) + 10;
    // Monsters speed
    const speed = Math.floor(Math.random() * 100) + 100;
    monsters.push(
      createEntity(
        "monster",
        {
          x,
          y,
          width,
          height,
          color,
          speed,
        },
        engine
      ) as Monster
    );
  }
  return monsters;
}

export { createEntity, createPlayer, createMonsters };
