import Entity from "../../entities/entity/entity";
import Player from "../../entities/player/player";
import {
  createMonsters,
  createPlayer,
} from "../../entities/entityFactory/entityFactory";
import { EntityManager } from "../../engineUtils/EntityManager/EntityManager";
import { InputHandler } from "../../engineUtils/InputHandler/InputHandler";
import { Renderer } from "../../engineUtils/Renderer/Renderer";

export class Engine {
  canvas: HTMLCanvasElement;
  ctx: CanvasRenderingContext2D;
  entityManager: EntityManager;
  inputHandler: InputHandler;
  renderer: Renderer;
  lastRender: number = 0;
  tickAccumulator: number = 0;
  player: Player | null = null;
  gameField: GameField;

  constructor(canvas: HTMLCanvasElement) {
    this.canvas = canvas;
    this.ctx = canvas.getContext("2d")!;
    this.entityManager = new EntityManager();
    this.renderer = new Renderer(canvas);
    this.gameField = this.renderer.gameField;

    // Initialize inputHandler with null player initially
    this.inputHandler = new InputHandler(null);
  }

  update(gameTick: number) {
    this.entityManager.updateAllEntities(
      this.gameField,
      gameTick,
      this.canvas,
      this.inputHandler.keys,
      this.player as Player
    );
  }

  render() {
    this.renderer.render(this.entityManager.entities, this.player as Player);
  }

  gameLoop(timestamp: number = 0) {
    // Calculate deltaTime
    let deltaTime = (timestamp - this.lastRender) / 1000;
    this.lastRender = timestamp;

    // Fixed time step for game logic
    const gameTick = 1 / 60; // 60 updates per second
    this.tickAccumulator += deltaTime;

    while (this.tickAccumulator >= gameTick) {
      this.update(gameTick);
      this.tickAccumulator -= gameTick;
    }

    this.render();
    requestAnimationFrame(this.gameLoop.bind(this));
  }

  start() {
    this.lastRender = performance.now();
    this.tickAccumulator = 0;
    this.gameLoop();
    document.addEventListener("keydown", this.inputHandler.handleKeyDown);
    document.addEventListener("keyup", this.inputHandler.handleKeyUp);
  }

  stop() {
    document.removeEventListener("keydown", this.inputHandler.handleKeyDown);
    document.removeEventListener("keyup", this.inputHandler.handleKeyUp);
  }

  addEntity(entity: Entity) {
    this.entityManager.addEntity(entity, this.gameField);
    if (entity instanceof Player) {
      this.player = entity;
      this.inputHandler = new InputHandler(this.player);
    }
  }
}

let globalEngineInstance: Engine | null = null;

export function initializeEngine(canvas: HTMLCanvasElement): Engine {
  if (globalEngineInstance) {
    return globalEngineInstance;
  }

  const engine = new Engine(canvas);
  const { gameField } = engine.renderer;
  const player = createPlayer(gameField.width, gameField.height, engine);
  const monsters = createMonsters(
    gameField.width,
    gameField.height,
    Math.floor(Math.random() * 4) + 4,
    engine
  );

  engine.addEntity(player);
  monsters.forEach((monster) => engine.addEntity(monster));
  engine.player = player; // Set the player explicitly

  globalEngineInstance = engine;
  return engine;
}

export default Engine;

export interface GameField {
  width: number;
  height: number;
}
