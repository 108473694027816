import { GameField } from "../../core/Engine/Engine";
import Entity from "../../entities/entity/entity";
import Monster from "../../entities/monster/monster";
import Player from "../../entities/player/player";
import Projectile from "../../entities/projectile/projectile";
import { MapLoader } from "./MapLoader";

export class Renderer {
  canvas: HTMLCanvasElement;
  ctx: CanvasRenderingContext2D;
  offScreenCanvas: HTMLCanvasElement;
  offScreenCtx: CanvasRenderingContext2D;
  gameField: GameField = { width: 0, height: 0 };
  mapLoader: MapLoader;

  constructor(canvas: HTMLCanvasElement) {
    this.canvas = canvas;
    this.ctx = canvas.getContext("2d")!;
    this.mapLoader = new MapLoader();

    this.offScreenCanvas = document.createElement("canvas");
    this.offScreenCtx = this.offScreenCanvas.getContext("2d")!;
    this.updateGameFieldDimensions();

    const interval = setInterval(() => {
      console.log("inside setInterval block");
      if (this.mapLoader.imagesLoaded) {
        console.log("calling render game field");
        this.renderGameField();
        clearInterval(interval);
      }
    }, 100);
  }

  updateGameFieldDimensions() {
    const menuBarWidth = this.canvas.width * 0.2;
    this.gameField = {
      width: this.canvas.width - menuBarWidth,
      height: this.canvas.height,
    };
    this.offScreenCanvas.width = this.gameField.width;
    this.offScreenCanvas.height = this.gameField.height;
  }

  render(entities: Entity[], player: Player) {
    if (!this.ctx) return;

    // Clear the main canvas
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

    this.ctx.save();

    const offSetX = this.canvas.width / 2 - player.x;
    const offSetY = this.canvas.height / 2 - player.y;

    this.ctx.translate(offSetX, offSetY);
    this.ctx.drawImage(this.offScreenCanvas, 0, 0);
    this.renderEntities(entities);
    this.ctx.restore();
    this.renderMenuBar(player);
  }

  renderGameField() {
    this.updateGameFieldDimensions();
    if (!this.offScreenCtx) return;

    const tileSize = 32;
    const mapData = this.mapLoader.mapData;
    const tileImages = this.mapLoader.tileImages;

    console.log("Rendering game field");
    console.log("Map data:", mapData);
    console.log("Tile images:", tileImages);

    // Clear the off-screen canvas
    this.offScreenCtx.clearRect(
      0,
      0,
      this.offScreenCanvas.width,
      this.offScreenCanvas.height
    );

    for (let y = 0; y < mapData.length; y++) {
      for (let x = 0; x < mapData[y].length; x++) {
        const tileType = mapData[y][x];
        const tileImage = tileImages[tileType];

        if (tileImage) {
          this.offScreenCtx.drawImage(
            tileImage,
            x * tileSize,
            y * tileSize,
            tileSize,
            tileSize
          );
        } else {
          this.offScreenCtx.fillStyle = "black";
          this.offScreenCtx.fillRect(
            x * tileSize,
            y * tileSize,
            tileSize,
            tileSize
          );
        }
      }
    }

    // Draw the off-screen canvas onto the main canvas
    this.ctx.drawImage(this.offScreenCanvas, 0, 0);
  }

  renderEntities(entities: Entity[]) {
    for (const entity of entities) {
      const scaledX = this.scale(entity.x, "x");
      const scaledY = this.scale(entity.y, "y");
      const scaledWidth = this.scale(entity.width, "x");
      const scaledHeight = this.scale(entity.height, "y");

      if (scaledX < this.canvas.width - this.canvas.width * 0.2) {
        if (entity instanceof Player || entity instanceof Monster) {
          this.ctx.beginPath();
          this.ctx.arc(
            scaledX + scaledWidth / 2,
            scaledY + scaledHeight / 2,
            scaledWidth / 2,
            0,
            2 * Math.PI
          );
          this.ctx.fillStyle = entity.color;
          this.ctx.fill();
        }
        // RENDER PROJECTILES
        if (entity instanceof Projectile) {
          this.ctx.fillStyle = entity.color;
          this.ctx.fillRect(scaledX, scaledY, scaledWidth, scaledHeight);
        }
      }
    }
  }

  renderMenuBar(player: Player) {
    const menuBarWidth = this.canvas.width * 0.2;
    const menuBarHeight = this.canvas.height;
    const menuBarX = this.canvas.width - menuBarWidth;

    this.ctx.fillStyle = "darkgrey";
    this.ctx.fillRect(menuBarX, 0, menuBarWidth, menuBarHeight);

    // Render health bar
    const healthBarWidth = menuBarWidth * 0.8;
    const healthBarHeight = 20;
    const healthBarX = menuBarX + (menuBarWidth - healthBarWidth) / 2;
    const healthBarY = 20;

    this.ctx.fillStyle = "red";
    this.ctx.fillRect(healthBarX, healthBarY, healthBarWidth, healthBarHeight);

    const currentHealthWidth = (player.health / 100) * healthBarWidth;
    this.ctx.fillStyle = "green";
    this.ctx.fillRect(
      healthBarX,
      healthBarY,
      currentHealthWidth,
      healthBarHeight
    );

    // Render health text
    this.ctx.fillStyle = "black";
    this.ctx.font = "20px Arial";
    this.ctx.fillText(`HP: ${player.health}`, healthBarX, healthBarY + 40);
  }

  scale(value: number, axis: "x" | "y"): number {
    const menuBarWidth = this.canvas.width * 0.2;
    const gameFieldWidth = this.canvas.width - menuBarWidth;
    const gameFieldHeight = this.canvas.height;

    if (axis === "x") {
      return value * (gameFieldWidth / this.gameField.width);
    } else {
      return value * (gameFieldHeight / this.gameField.height);
    }
  }
}
