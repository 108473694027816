import Entity from "../entity/entity";
import Player from "../player/player";

class Projectile extends Entity {
  dx: number;
  dy: number;
  speed: number;

  constructor(x: number, y: number, dx: number, dy: number, speed: number) {
    super(x, y, 5, 5, "yellow", 1);
    this.dx = dx;
    this.dy = dy;
    this.speed = speed;
  }

  update(gameTick: number, canvas?: HTMLCanvasElement, player?: Player) {
    this.x += this.dx * this.speed * gameTick;
    this.y += this.dy * this.speed * gameTick;
  }
}

export default Projectile;
