import { GameField } from "../../core/Engine/Engine";
import Entity from "../../entities/entity/entity";
import Player from "../../entities/player/player";
import Projectile from "../../entities/projectile/projectile";

export class EntityManager {
  entities: Entity[] = [];
  projectilesToRemove: Entity[] = [];

  addEntity(entity: Entity, gameField: GameField) {
    if (!this.isOutOfBounds(entity, gameField)) {
      this.entities.push(entity);
    }
  }

  isOutOfBounds(entity: Entity, gameField: GameField): boolean {
    return (
      entity.x < 0 ||
      entity.y < 0 ||
      entity.x + entity.width > gameField.width ||
      entity.y + entity.height > gameField.height
    );
  }

  checkCollision(entity1: Entity, entity2: Entity): boolean {
    return (
      entity1.x < entity2.x + entity2.width &&
      entity1.x + entity1.width > entity2.x &&
      entity1.y < entity2.y + entity2.height &&
      entity1.y + entity1.height > entity2.y
    );
  }

  updateEntityPosition(entity: Entity, gameField: GameField): void {
    // Keep the entity within game field bounds
    entity.x = Math.max(0, Math.min(entity.x, gameField.width - entity.width));
    entity.y = Math.max(
      0,
      Math.min(entity.y, gameField.height - entity.height)
    );
  }

  updateAllEntities(
    gameField: GameField,
    gameTick: number,
    canvas: HTMLCanvasElement,
    inputHandlerKeys: { [key: string]: boolean },
    player: Player
  ): void {
    this.entities.forEach((entity) => {
      if (entity instanceof Player) {
        entity.update(gameTick, canvas, entity, inputHandlerKeys);
      } else {
        entity.update(gameTick, canvas, player);
      }
    });

    this.removeOutOfBoundsEntities(gameField);

    this.entities.forEach((entity) => {
      this.updateEntityPosition(entity, gameField);
    });

    this.checkIfPlayerHit(player);

    this.entities = this.entities.filter(
      (entity) => !this.projectilesToRemove.includes(entity)
    );
    this.projectilesToRemove = [];
  }

  shouldRemoveEntity(entity: Entity, gameField: GameField): boolean {
    if (entity instanceof Projectile) {
      return this.isOutOfBounds(entity, gameField);
    }
    return false;
  }

  removeOutOfBoundsEntities(gameField: GameField): void {
    this.entities = this.entities.filter((entity) => {
      if (this.shouldRemoveEntity(entity, gameField)) {
        this.projectilesToRemove.push(entity);
        return false;
      }
      return true;
    });
  }

  checkIfPlayerHit(player: Player): void {
    this.entities.forEach((entity) => {
      if (entity instanceof Projectile) {
        if (this.checkCollision(entity, player)) {
          this.projectilesToRemove.push(entity);
          player.handlePlayerHit();
        }
      }
    });
  }
}
