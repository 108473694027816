class Entity {
  x: number;
  y: number;
  width: number;
  height: number;
  color: string;
  speed: number;
  direction: number;

  constructor(
    x: number,
    y: number,
    width: number,
    height: number,
    color: string,
    speed: number
  ) {
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
    this.color = color;
    this.speed = speed;
    this.direction = Math.random() * 2 * Math.PI; // Initial random direction
  }

  update(
    gameTick?: number,
    canvas?: HTMLCanvasElement,
    player?: Entity,
    keys?: { [key: string]: boolean }
  ): void {
    // This method is a placeholder and will be overridden in subclasses
  }

  render(ctx: CanvasRenderingContext2D) {
    ctx.fillStyle = this.color;
    ctx.fillRect(this.x, this.y, this.width, this.height);
  }
}

export default Entity;
